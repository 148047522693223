const libphonenumber = require('libphonenumber-js');

module.exports = class SecurityOffice {
	constructor (object) {
		this.Id = object.SecurityOfficeId;
		this.Name = object.Name;
		this.Code = object.Code;
		this.Address = object.Address;
		this.City = object.City;
		this.State = object.State;
		this.ZipCode = object.ZipCode;
		this.Country = object.Country;
		this.PhoneNumber = libphonenumber.parsePhoneNumberFromString(object.PhoneNumber).formatInternational();
		this.Email = object.Email;
		this.Latitude = object.Latitude;
		this.Longitude = object.Longitude;
		this.Altitude = object.Altitude;
		this.OfficeTypeId = object.OfficeTypeId;
		this.OfficeTypeName = object.OfficeTypeName;
		this.StatusId = object.StatusId;
		this.Status = object.Status;
		this.Admins = object.Admins;
	}
};
