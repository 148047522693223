const { secondsToTimer } = require('./../../../utils/luxon-formater');

module.exports = class Timer {
	constructor (object) {
		this.NonRetailTaskId = object.CheckInOutId;
		this.TimerForAction = object.TimerForAction;
		this.ActionStartTimeUtc = object.ActionStartTimeUtc;
		this.ActionStartDateUtc = object.ActionStartDateUtc;
		this.ActionStartTimeLocal = object.ActionStartTimeLocal;
		this.ActionStartDateLocal = object.ActionStartDateLocal;
		this.ActionStopDateUtc = object.ActionStopDateUtc;
		this.Latitude = object.Latitude;
		this.Longitude = object.Longitude;
		this.UserProfile = object.UserProfile;
		this.Pings = object.Pings !== null ? object.Pings.map(p => {
			return {
				NonRetailTaskId: p.TaskingId,
				Latitude: p.Latitude,
				Longitude: p.Longitude,
				TimerForAction: secondsToTimer(p.TimerLeft),
			};
		}) : null;
	}
};
