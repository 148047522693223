module.exports = class ChatMessage {
	constructor (object) {
		this.SessionAutoId = object.SessionAutoId;
		this.ChatSessionId = object.ChatSessionId;
		this.ChatStatus = object.ChatStatus;
		this.ChatStartTime = object.ChatStartTime;
		this.ChatAnsweredTime = object.ChatAnsweredTime;
		this.ChatStopTime = object.ChatStopTime;
		this.URLForMedia = object.URLForMedia;
		this.SessionInitiatedBy = object.SessionInitiatedBy;
		this.SessionFinishedBy = object.SessionFinishedBy;
		this.Longitude = object.Longitude;
		this.Latitude = object.Latitude;
		this.HorizontalAccuracy = object.HorizontalAccuracy;
		this.MobileCallerId = object.MobileCallerId;
		this.MobileCallerName = object.MobileCallerName;
		this.MobileSessionType = object.MobileSessionType;
		this.MobileAppVersion = object.MobileAppVersion;
		this.MobileOSVersion = object.MobileOSVersion;
		this.MobilePhoneModel = object.MobilePhoneModel;
		this.SecurityOfficerId = object.SecurityOfficerId;
		this.SecurityOfficerName = object.SecurityOfficerName;

		this.MessagesArray = object.MessagesArray;
		this.CallerProfile = object.CallerProfile;
	}
};
