const libphonenumber = require('libphonenumber-js');

module.exports = class RegionalOffice {
	constructor (object) {
		this.Id = object.RegionalOfficeId;
		this.Name = object.Name;
		this.Code = object.Code;
		this.PhoneNumber = libphonenumber.parsePhoneNumberFromString(object.PhoneNumber).formatInternational();
		this.OrganizationName = object.OrganizationName;
		this.Status = object.Status === 'ACTIVE' ? 'Active' : object.Status === 'PENDING' ? 'Pending' : 'Inactive';
		this.Address = object.Address;
		this.City = object.City;
		this.State = object.State;
		this.ZipCode = object.ZipCode;
		this.Country = object.Country;
		this.Latitude = object.Latitude;
		this.Longitude = object.Longitude;
		this.Opening = object.Opening;
		this.Closing = object.Closing;
		this.Servicing = object.Servicing;
		this.FormatedAddress = object.Address + ', ' + object.City + ', ' + object.State + ', ' + object.ZipCode + ', ' + object.Country;
		this.StatusId = object.StatusId;
		this.Altitude = object.Altitude;
		this.OfficeTypeId = object.OfficeTypeId;
		this.WorkScheduleId = object.WorkScheduleId;
		this.OrganizationId = object.OrganizationId;
		this.WeekSchedule = object.WeekSchedule;
		this.HolidaysTemplate = object.HolidaysTemplate;
		this.AutoCloseTime = object.AutoCloseTime;
	}
};
