const libphonenumber = require('libphonenumber-js');

module.exports = class UserExternalForSurvey {
	constructor (object) {
		this.ExternalUserId = object.ExternalUserId;
		this.Name = object.Name;
		this.Email = object.Email;
		this.Phone = libphonenumber.parsePhoneNumberFromString(object.Phone).formatInternational();
		this.BranchOfficeId = object.BranchOfficeId;
	}
};
