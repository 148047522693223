const ContactData = require('./ContactData');
const libphonenumber = require('libphonenumber-js');

module.exports = class Organization {
	constructor (object) {
		this.Id = object.OrganizationId;
		this.Name = object.Name;
		this.Address = object.Address;
		this.City = object.City;
		this.State = object.State;
		this.ZipCode = object.ZipCode;
		this.Country = object.Country;
		this.PhoneNumber = libphonenumber.parsePhoneNumberFromString(object.PhoneNumber).formatInternational();
		this.Email = object.Email;
		this.Latitude = object.Latitude;
		this.FormatedAddress = `${object.Address}, ${object.City}, ${object.State}, ${object.ZipCode}, ${object.Country}`;
		this.AdminFullName = `${object.AdminName} ${object.AdminSurname}`;
		this.AdminEmail = `${object.AdminEmail}`;
		this.Longitude = object.Longitude;
		this.Altitude = object.Altitude;
		this.PrimaryContact = new ContactData(object.PrimaryContactName, object.PrimaryContactSurname, object.PrimaryContactEmail, object.PrimaryContactPhone, null);
		this.SecondaryContact = new ContactData(object.SecondaryContactName, object.SecondaryContactSurname, object.SecondaryContactEmail, object.PrimaryContactPhone, null);
		this.StatusId = object.StatusId;
		this.AdminData = new ContactData(object.AdminName, object.AdminSurname, object.AdminEmail, object.AdminPhoneNumber, object.AdminAvatar);
		this.SecurityOffices = object.SecurityOffices !== undefined ? object.SecurityOffices.map(so => {
			return {
				Id: so.SecurityOfficeId,
				Name: so.Name,
			};
		}) : null;
		this.HeadOfficeId = object.headOfficeId;
	}
};
