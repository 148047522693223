const libphonenumber = require('libphonenumber-js');

module.exports = class SecurityOfficer {
	constructor (object) {
		this.Id = object.UserId;
		this.FirstName = object.Name;
		this.LastName = object.Surname;
		this.Avatar = object.Avatar;
		this.RoleId = object.RoleId;
		this.RoleName = object.RoleName;
		this.StatusId = object.StatusId;
		this.Status = object.Status;
		this.PhoneNumber = libphonenumber.parsePhoneNumberFromString(object.PhoneNumber).formatInternational();
		this.Email = object.Email;
		this.RoleNameDisplay = object.RoleNameDisplay;
	}
};
